<template>
  <div class="teacher-detail">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.query.tid">
        讲师详情
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.query.pid">
        <el-breadcrumb-item>
            <span @click="$router.push('/teacherlist')">风采展示&nbsp;/&nbsp;</span>
        </el-breadcrumb-item>
        详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail-wrapper">
      <div class="detail-top">
        <div class="detail-header">
          <img :src="'https://tot.xlteacher.cn' + info.pic" v-if="info.pic" />
        </div>
        <div class="detail-recommond" style="width: 490px">
          <div class="detail-name">{{ info.name }}</div>
          <div class="detail-education">{{ info.education }}</div>
          <div class="detail-duty" v-if="info.position">
            职称：{{ info.position }}
          </div>
        </div>
      </div>
      <div class="detail-bottom" v-html="info.infos"></div>
      <div style="float: right; cursor: pointer" @click="toMoreList">
        更多>>>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },

  components: {},

  computed: {},
  mounted() {
    if (this.$route.query.tid) {
      this.getInfo()
    }
    if (this.$route.query.pid) {
      this.getOtherInfo()
    }
  },
  methods: {
    toMoreList() {
      let type
      if (this.$route.query.tid) {
        type = "teacher"
      }
      if (this.$route.query.pid) {
        type = "style"
      }
      this.$router.push(`/teacherlist?${type}=1`)
    },

    async getInfo() {
      let { data } = await this.axios.get(
        `/index/teacher_detail?id=${this.$route.query.tid}`
      )
      this.info = data.info
    },
    async getOtherInfo() {
      let { data } = await this.axios.get(
        `/index/style_detail?id=${this.$route.query.pid}`
      )
      this.info = data.info
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-detail {
  width: 1200px;
  margin: 0 auto;
  .detail-wrapper {
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 5%);
    padding: 30px;
    box-sizing: border-box;
    padding-bottom: 119px;
    .detail-top {
      //   padding: 30px;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
      .detail-header {
        width: 188px;
        // height: 272px;
        margin-left: 41px;
        margin-top: 4px;
        float: left;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .detail-recommond {
        // height: 280px;
        margin-left: 50px;
        width: 491px;
        font-size: 16px;
        color: #3d3d3d;
        position: relative;
        float: left;
      }
      .detail-name {
        font-size: 24px;
        font-weight: bold;
      }
      .detail-education {
        margin-top: 20px;
      }
      .detail-duty {
        margin-top: 20px;
      }
      .detail-title {
        margin-top: 20px;
      }
    }
    .detail-bottom {
      width: 100%;
      font-size: 16px;
      color: #333333;
      padding: 40px;
      box-sizing: border-box;
    }
  }
}
</style>
