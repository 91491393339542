import { render, staticRenderFns } from "./TeacherDetail.vue?vue&type=template&id=d2366548&scoped=true&"
import script from "./TeacherDetail.vue?vue&type=script&lang=js&"
export * from "./TeacherDetail.vue?vue&type=script&lang=js&"
import style0 from "./TeacherDetail.vue?vue&type=style&index=0&id=d2366548&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2366548",
  null
  
)

export default component.exports